<template>
  <div class="list-wrap wrapper">
    <BaseLoad v-if="loading" />
    <div class="list" v-else>
      <h2>我的游戏</h2>
      <div class="my-game">
        <div class="between">
          <div class="sub-nav">
            <li
              v-for="(item, index) in subTab"
              :key="item"
              :class="[index == 1 ? 'sub-act' : '']"
              @click="changeSubTab(index)"
            >
              {{ item }}
            </li>
          </div>
          <p class="sub-tip">
            <span>已购买</span>
            <span class="sub-tip-total"> {{ total }} </span>个
          </p>
        </div>
        <div class="line"></div>
        <ListLoad v-if="listLoad && !loading" />
        <ul class="game-list" v-if="!listLoad">
          <li class="game-item" v-for="item in list" :key="item.config_id">
            <img class="cover" :src="preSrc + item.cover" alt="" />
            <h2 :title="item.title" class="ellipsis">{{ item.title }}</h2>
            <div class="flex favor-work">
              <p
                v-if="item.config_id"
                class="preview flex"
                @click.stop="
                  previewCreateGame(item.game_id, item.config_id, item.title)
                "
              >
                <i class="iconfont icon-yulan"></i>
                <span>预览</span>
              </p>
              <p
                v-else
                class="preview flex"
                @click.stop="previewCreateGame(item.game_id, '', item.title)"
              >
                <i class="iconfont icon-yulan"></i>
                <span>预览</span>
              </p>
              <span class="slash">/</span>
              <span class="delete" @click.stop="createGame(item.game_id)">
                <i class="iconfont icon-bianji1"></i>
                创建
              </span>
            </div>
          </li>
        </ul>
      </div>
      <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
      <!-- 分页 -->
      <div class="pagination-con" v-if="list.length > 0 && total > 6">
        <el-pagination
          :page-size="pageSize"
          :pager-count="5"
          layout="prev, pager, next"
          :current-page="page"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!-- 分页end -->
    </div>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
import { handlePreviewCreate } from "@/tools/GlobalFunction";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      listLoad: false,
      loading: true,
      subTab: ["已创建", "已购买"],
      list: [],
      page: 1,
      total: 0,
      pageSize: 6,
      cateList: [],
      currentType: 0,
    };
  },
  created() {
    let _self = this;
    _self.gameList(_self.page);
  },
  methods: {
    //我的游戏 type 1创建 2购买
    gameList(page, cate_id = "") {
      let _self = this;
      _self.axios
        .post(
          config.url.myGame,
          _self.$qs.stringify({ page, size: _self.pageSize, type: 2, cate_id })
        )
        .then((res) => {
          if (res.code == 200) {
            _self.list = res.data.list;
            _self.total = res.data.total;
            _self.loading = false;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    // 创建游戏
    createGame(id) {
      let _self = this;
      _self.$router.push({
        name: "game-create",
        params: {
          id: id,
        },
      });
    },
    // 预览
    previewCreateGame(gameId, configId = "", title) {
      let _self = this;
      handlePreviewCreate(gameId, configId, title, _self);
    },
    // 切换子选项卡
    changeSubTab(index) {
      let _self = this;
      if (index == 1) {
        return;
      }
      _self.$router.push({
        name: "my-game",
      });
    },
    // 改变分页
    handleCurrentChange(val) {
      let _self = this;
      _self.page = val;
      _self.gameList(val, _self.currentType);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #f9f9f9;
  .content {
    background: #fafafa;
  }
}
.con {
  display: flex;
  margin-top: 60px;
  background: #f9f9f9;
  .tab {
    width: 190px;
    height: 440px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 10px;
    background: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
      padding: 30px 15px;
      box-sizing: border-box;
    }
    h3::before {
      content: "";
      display: inline-block;
      background: url("../../assets/img/home/pre-icon.png") center center
        no-repeat;
      background-size: 100%;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
    li {
      padding: 15px 35px;
      box-sizing: border-box;
      // width: 100%;
      border-right: 2px solid #fff;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
    li.active {
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
  }
  .list {
    width: 980px;
    padding: 30px;
    box-sizing: border-box;
    background: #fff;
    border-radius: $border-radius-main;
    h2 {
      margin-bottom: 40px;
    }
    .list-item {
      background: #f9f9f9;
      padding: 20px 30px;
      box-sizing: border-box;
      border-radius: $border-radius-main;
      margin-bottom: 30px;
      > p {
        margin-bottom: 20px;
        color: #9f9f9f;
        font-size: 16px;
      }
    }
  }

  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor i,
  .work img,
  .btn:hover,
  .sub-nav:hover,
  .favor-work:hover {
    cursor: pointer;
  }
  .favor-work {
    color: $font-color-9f;
    justify-content: flex-start;
    .icon-yulan {
      color: #34c3f9;
      margin: 3px 6px 0 0;
    }
    .slash {
      margin: 0 10px;
    }
    .favor {
      margin-top: -3px;
    }
    .favor i {
      margin-right: 6px;
    }
    .work {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    .icon-bianji1 {
      color: #03cca6;
    }
  }

  .my-game,
  .my-favor,
  .my-workplace {
    width: 920px;
    .sub-nav {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      li {
        font-size: 18px;
        color: $font-color-51;
        width: 120px;
        text-align: center;
        flex-shrink: 0;
        flex-grow: 0;
      }
      li::after {
        content: "";
        display: block;
        margin: 20px auto 0;
        width: 50px;
        height: 5px;
        background: #f9f9f9;
        border-radius: 5px;
      }
      li.sub-act::after {
        content: "";
        display: block;
        margin: 20px auto 0;
        width: 50px;
        height: 5px;
        background: $color-main;
        border-radius: 5px;
      }
    }
  }
  .line {
    width: 100%;
    height: 10px;
    background: #f9f9f9;
    margin-top: -8px;
    border-radius: 5px;
  }
  .game-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    background: #fff;
    .game-item {
      width: 280px;
      height: 300px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: $border-radius-main;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
      margin: 0 40px 20px 0;
      .cover {
        width: 160px;
        height: 160px;
        object-fit: contain;
        margin: 0 auto 16px;
      }
      h2 {
        font-size: 18px;
        color: $font-color-main;
        margin: 0 auto 24px;
        text-align: center;
      }
    }
    .game-item:nth-child(3n) {
      margin: 0 0 20px 0;
    }
  }
  .my-favor-con {
    margin-top: 30px;
  }
  .game-list-favor {
    .game-item {
      width: 280px;
      height: 250px;
    }
  }
  .sub-tip {
    font-size: 18px;
    color: $font-color-51;
    .sub-tip-total {
      color: $color-main;
    }
  }
  .list-item.workpalce-course-list {
    padding: 0;
    background: #fff;
    margin-top: 30px;
    .item-r {
      h3 {
        margin-bottom: 16px;
      }
    }
  }
  .delete {
    color: $font-color-9f;
    .icon-shanchu {
      color: #ff4d4f;
    }
  }
  .buy {
    width: 60px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #34c3f9;
    background: #eefaff;
    border: 1px solid #34c3f9;
    border-radius: 5px;
  }
  .hide {
    opacity: 0;
  }
}
</style>
